<template>
  <div class="">
    <button class="btn-search" v-b-modal.searchModal @click="hideSideMenu">
      <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
    </button>

    <b-modal id="searchModal" centered title="Search" ref="searchModal">
      <form @submit.prevent="search">
        <div class="form-group">
          <input
            type="search"
            name="search"
            class="form-control"
            v-model="searchWord"
          />
        </div>
      </form>
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="success" type="submit" @click="search" :disabled="loading"
        :class="{ 'border-main': loading }">
          <span v-if="!loading">Search</span>
          <span v-else>
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchWord: null,
      loading: false,
    };
  },
  methods: {
    search() {
      this.loading = true;
      // this.$refs.searchModal.hide();
      this.$router.push(
        {
          name: "searchResults",
          query: {
            searchWord: this.searchWord,
          },
        },
        () => {
          this.$refs.searchModal.hide();
          this.$router.go(0)
          this.loading = false;
        }
      );
    },
    hideSideMenu() {
      let sideMenu = document.querySelector(".b-sidebar-backdrop");
      if (sideMenu) {
        sideMenu.click();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-search {
  padding: 8px 12px;
  border-radius: 5px;
  text-align: center;
}
</style>
