<template>
  <div class="Footer-wrapper">
    <footer>
      <div class="container">
        <div class="top-footer">
          <div class="row justify-content-center">
            <div class="col-md-3 col-sm-12 d-flex align-items-center">
              <router-link to="/">
                <img
                  src="@/assets/images/logo.png"
                  alt="logo"
                  height="160"
                  class="logo-image"
                />
              </router-link>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="row footer-links">
                <div class="col-md-6 col-sm-12">
                  <router-link to="/">Home</router-link>
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link
                    :to="{ name: 'mediaCenter', query: { activeTab: 'image' } }"
                    >Media Center</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link to="/about">About ATA</router-link>
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link
                    :to="{ name: 'podcasts', query: { id: podcastsId } }"
                    >Resources</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link :to="{ name: 'courses', query: { id: 1 } }"
                    >Services</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link :to="{ name: 'privacyPolicy' }"
                    >Privacy Policy</router-link
                  >
                </div>

                <div class="col-md-6 col-sm-12">
                  <router-link :to="{ name: 'summerProgram', query: { id: 8 } }"
                    >Events</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link :to="{ name: 'termsConditions' }"
                    >Terms & Conditions</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link :to="{ name: 'audios', query: { id: 13 } }"
                    >Store</router-link
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <router-link
                    :to="{ name: 'contactUs', query: { type: 'inquery' } }"
                    >Contact Us</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 mt-3" v-if="ContactUsInfo">
              <div class="row">
                <div class="col-4" v-if="ContactUsInfo.linkedin_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.linkedin_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-linkedin-in"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="col-4" v-if="ContactUsInfo.facebook_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.facebook_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-facebook-f"
                        />
                      </span>
                    </a>
                  </div>
                </div>

                <div class="col-4" v-if="ContactUsInfo.instagram_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.instagram_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-instagram"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="col-4" v-if="ContactUsInfo.twitter_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.twitter_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-twitter"
                        />
                      </span>
                    </a>
                  </div>
                </div>

                <div class="col-4" v-if="ContactUsInfo.youtube_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.youtube_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-youtube"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="col-4" v-if="ContactUsInfo.whatsapp_number">
                  <div class="social">
                    <a
                      class="text-white"
                      target="_blank"
                      :href="`https://api.whatsapp.com/send?phone=${ContactUsInfo.whatsapp_number}&text=Hello`"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-whatsapp"
                        />
                      </span>
                    </a>
                  </div>
                </div>

                <div class="col-4" v-if="ContactUsInfo.threads_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.threads_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <img
                          src="@/assets/images/social/threads.webp"
                          alt="social-network-image"
                          class="threads-image"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div class="col-4" v-if="ContactUsInfo.tiktok_link">
                  <div class="social">
                    <a
                      class="first-icon"
                      :href="ContactUsInfo.tiktok_link"
                      target="_blank"
                    >
                      <span
                        class="social-link"
                        data-aos="fade-right"
                        data-aos-delay="700"
                      >
                        <font-awesome-icon
                          size="xl"
                          icon="fa-brands fa-tiktok"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="contact">
                <div class="d-flex align-items-start custom-flex-md-column">
                  <div
                    class="contact-info d-flex"
                    v-if="ContactUsInfo.hotline_usa"
                  >
                    <p class="">
                      <span class="phone-icon"
                        ><font-awesome-icon icon="fa-solid fa-phone"
                      /></span>
                      <span class="mb-3 h6"><b>USA Office</b></span>
                      <span
                        class="phone-call d-block mt-2"
                        v-if="ContactUsInfo.hotline_usa"
                        v-html="ContactUsInfo.hotline_usa"
                      ></span>
                    </p>
                  </div>
                  <div
                    class="contact-info d-flex mx-4"
                    v-if="ContactUsInfo.hotline_eg"
                  >
                    <p class="">
                      <span class="phone-icon"
                        ><font-awesome-icon icon="fa-solid fa-phone"
                      /></span>
                      <span class="mb-3 h6"><b>EGYPT Office</b></span>
                      <span
                        class="phone-call d-block mt-2"
                        v-if="ContactUsInfo.hotline_eg"
                        v-html="ContactUsInfo.hotline_eg"
                      ></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-1">
          <hr />
        </div>
        <div class="bottom-footer mt-4 text-center">
          <p>
            © 2023 American Training Academy, LLC. All rights reserved. Designed
            by <span><a href="https://fabrica.com.eg/" target="_blank"> <ins>fabrica dev</ins> </a></span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getContactInfoData();
  },
  methods: {
    getContactInfoData() {
      this.$store.dispatch("getContactInfoData");
      this.$store.dispatch("getTerms");
      this.$store.dispatch("getPrivacy");
    },
  },
  computed: {
    ContactUsInfo() {
      return this.$store.getters.ContactInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/scss/layout/footer.scss");
.threads-image {
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
</style>
