<template>
  <ul class="nav-items">
    <li data-aos="fade-right" data-aos-delay="100">
      <router-link to="/">Home</router-link>
    </li>
    <li data-aos="fade-right" data-aos-delay="150">
      <router-link to="/about">About ATA</router-link>
    </li>
    <li class="dropdown" data-aos="fade-right" data-aos-delay="400" v-if="navLinks && navLinks.Service">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkServicesRouter }">Services</a>
          <div class="dropdown">
            <a @click="redirectSevicesPage(link)" v-for="(link, index) in navLinks.Service" :key="index">
              {{ link.name }}
            </a>
          </div>
        </li>
      </ul>
    </li>

    <li data-aos="fade-right" data-aos-delay="100" v-if="navLinks && navLinks.StudyAbroad && navLinks.StudyAbroad[0]">
      <router-link :to="{ name: 'studyAbroad', query: { id: navLinks.StudyAbroad[0].id } }">Study Abroad</router-link>
    </li>

    <li data-aos="fade-right" data-aos-delay="100" v-if="navLinks && navLinks.Recruitment && navLinks.Recruitment[0]">
      <router-link :to="{ name: 'recruitment', query: { id: navLinks.Recruitment[0].id } }">Recruitment</router-link>
    </li>

    <li class="dropdown" data-aos="fade-right" data-aos-delay="400" v-if="navLinks && navLinks.Events">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkEventsRouter }">Events</a>
          <div class="dropdown">
            <!-- <router-link
              :to="{ name: 'mediaCenter', query: { activeTab: 'image' } }"
              >Conference</router-link
            >
            <router-link
              :to="{ name: 'mediaCenter', query: { activeTab: 'video' } }"
              >Summer Program</router-link
            > -->

            <a @click="redirectEventsPage(link)" v-for="(link, index) in navLinks.Events" :key="index">
              {{ link.name }}</a>
          </div>
        </li>
      </ul>
    </li>
    <li class="dropdown" data-aos="fade-right" data-aos-delay="400">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkMediaRouter }">Media Center</a>
          <div class="dropdown">
            <a @click="goPage('image')" :to="{ name: 'mediaCenter', query: { activeTab: 'image' } }">Photos</a>
            <a @click="goPage('video')" :to="{ name: 'mediaCenter', query: { activeTab: 'video' } }">Videos</a>
          </div>
        </li>
      </ul>
    </li>
    <li class="dropdown" data-aos="fade-right" data-aos-delay="400" v-if="navLinks && navLinks.Resources">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkResourcesRouter }">Resources</a>
          <div class="dropdown">
            <a @click="redirectResourcesPage(link)" v-for="(link, index) in navLinks.Resources" :key="index">
              {{ link.name }}</a>
          </div>
        </li>
      </ul>
    </li>

    <li class="dropdown" data-aos="fade-right" data-aos-delay="400" v-if="navLinks && navLinks.Store">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkStoreRouter }">Store</a>
          <div class="dropdown">
            <a @click="redirectStorePage(link)" v-for="(link, index) in navLinks.Store" :key="index">
              {{ link.name }}</a>
          </div>
        </li>
      </ul>
    </li>
    <li class="dropdown" data-aos="fade-right" data-aos-delay="400" v-if="navLinks && navLinks.Store">
      <ul class="navbar-links p-0 m-0">
        <li class="navbar-dropdown">
          <a class="text-black" :class="{ 'router-link-exact-active': checkContactUsRouter }">Contact Us</a>
          <div class="dropdown">
            <a @click="goContactUsPage('inquery')"> Inqueries</a>
            <a @click="goContactUsPage('booking')"> Book ATA Expert</a>
          </div>
        </li>
      </ul>
    </li>

    <!-- <li data-aos="fade-right" data-aos-delay="150">
      <router-link to="/contactUs">Contact Us</router-link>
    </li> -->
  </ul>
</template>

<script>
export default {
  computed: {
    checkServicesRouter() {
      return (
        this.$route.path.includes("business") ||
        this.$route.path.includes("courses") ||
        this.$route.path.includes("connectWithCoach") ||
        this.$route.path.includes("connectWithMentor")
      );
    },
    checkMediaRouter() {
      return this.$route.path.includes("mediaCenter");
    },
    checkStoreRouter() {
      return (
        this.$route.path.includes("books") ||
        this.$route.path.includes("magazine") ||
        this.$route.path.includes("audios") || 
        this.$route.path.includes("souvenirs")
      );
    },
    checkResourcesRouter() {
      return (
        this.$route.path.includes("podcasts") ||
        this.$route.path.includes("blogs")
      );
    },
    checkEventsRouter() {
      return (
        this.$route.path.includes("SummerProgram") ||
        this.$route.path.includes("conference")
      );
    },
    checkContactUsRouter() {
      return this.$route.path.includes("contactUs");
    },
  },
  props: {
    navLinks: {
      type: Object,
    },
  },
  methods: {
    // redirectSevicesPage(link) {
    //   if (link.type == 0) {
    //     this.$router.push(
    //       {
    //         name: "servicesPage",
    //         query: { id: link.id },
    //       },
    //       () => {
    //         this.$router.go(0);
    //       }
    //     );
    //   } else if (link.type == 1) {
    //     if (link.media_type == 1) {
    //       this.$router.push(
    //         {
    //           name: "courses",
    //           query: {
    //             id: link.id,
    //           },
    //         },
    //         () => {
    //           this.$router.go(0);
    //         }
    //       );
    //     } else if (link.media_type == 2) {
    //       this.$router.push(
    //         {
    //           name: "blogs",
    //           query: {
    //             id: link.id,
    //           },
    //         },
    //         () => {
    //           this.$router.go(0);
    //         }
    //       );
    //     } else if (link.media_type == 3) {
    //       this.$router.push(
    //         {
    //           name: "podcasts",
    //           query: {
    //             id: link.id,
    //           },
    //         },
    //         () => {
    //           this.$router.go(0);
    //         }
    //       );
    //     } else if (link.media_type == 4) {
    //       this.$router.push(
    //         {
    //           name: "books",
    //           query: {
    //             id: link.id,
    //           },
    //         },
    //         () => {
    //           this.$router.go(0);
    //         }
    //       );
    //     }
    //   } else if (link.type == 2) {
    //     this.$router.push(
    //       {
    //         name: "courses",
    //         params: {
    //           id: link.id,
    //         },
    //       },
    //       () => {
    //         this.$router.go(0);
    //       }
    //     );
    //   }
    // },

    redirectSevicesPage(link) {
      if (link.name == "Connect with a Mentor") {
        this.$router
          .push({ path: "/connectWithMentor", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Connect with a Coach") {
        this.$router
          .push({ path: "/connectWithCoach", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Business Consultation") {
        this.$router
          .push({ path: "/business", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Learning and Development courses") {
        this.$router
          .push({ path: "/courses", query: { id: link.id } })
          .catch(() => { });
      }
    },
    redirectStorePage(link) {
      if (link.name == "Audio") {
        this.$router
          .push({ path: "/audios", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Books") {
        this.$router
          .push({ path: "/books", query: { id: link.id } })
          .catch(() => { });
      }

      else if (link.name == "Magazine") {
        this.$router
          .push({ path: "/magazine", query: { id: link.id } })
          .catch(() => { });
      }
      else if (link.name == "Souvenirs") {
        this.$router
          .push({ path: "/souvenirs", query: { id: link.id } })
          .catch(() => { });
      }
    },
    redirectResourcesPage(link) {
      if (link.name == "Blog") {
        this.$router
          .push({ path: "/blogs", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Podcast") {
        this.$router
          .push({ path: "/podcasts", query: { id: link.id } })
          .catch(() => { });
      }
    },
    redirectEventsPage(link) {
      if (link.name == "Summer Program") {
        this.$router
          .push({ path: "/SummerProgram", query: { id: link.id } })
          .catch(() => { });
      } else if (link.name == "Conference") {
        this.$router
          .push({ path: "/conference", query: { id: link.id } })
          .catch(() => { });
      }
    },
    goContactUsPage(type) {
      this.$router
        .push({
          name: "contactUs",
          query: {
            type: type,
          },
        })
        .catch(() => { });
    },
    goPage(activeTab) {
      this.$router
        .push(
          {
            name: "mediaCenter",
            query: {
              activeTab: activeTab,
            },
          },

          () => {
            this.$router.go(0);
          }
        )
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/layout/Navbar.scss";

.navbar-links li.navbar-dropdown .dropdown {
  padding: 20px 30px;
  width: auto;
}
</style>
