import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;


//------ FontAwesome -------------------
import { library } from "@fortawesome/fontawesome-svg-core";

/* add some free styles */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, fab , far);
Vue.component("font-awesome-icon", FontAwesomeIcon);


//------ BootstrapVue -------------------

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


import LazyTube from "vue-lazytube";

Vue.use(LazyTube);


// import "./plugins/VueSlickCarousel"
import "./mixins";
import './filters';
// Styles: SCSS
import "./assets/scss/main.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
