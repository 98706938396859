import Vue from "vue";
Vue.mixin({
  computed: {
    mainDomain() {
      return process.env.VUE_APP_DOMAIN_NAME;
    },

    podcastsId() {
      return this.$store.getters.podcastsId;
    },
    BlogsId() {
      return this.$store.getters.BlogsId;
    },
    AudioId() {
      return this.$store.getters.AudioId;
    },
    MagazineId() {
      return this.$store.getters.MagazineId;
    },
    BooksId() {
      return this.$store.getters.BooksId;
    },
    SouvenirsId() {
      return this.$store.getters.SouvenirsId;
    },
    CoursesId() {
      return this.$store.getters.CoursesId;
    },
  },
  //   methods: {
  //     sucessMsg(text, icon = "success") {
  //       this.$swal({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 5000,
  //         timerProgressBar: true,
  //         icon: icon,
  //         title: text,
  //       });
  //     },
  //     errMsg(text, icon = "error") {
  //       this.$swal({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 5000,
  //         timerProgressBar: true,
  //         icon: icon,
  //         title: text,
  //       });
  //     },
  //   },
});
