import Vue from "vue";
import moment from "moment";
Vue.filter("fixedCurrency", function (el) {
  // check if the value is actually a string
  if (typeof el === "string") {
    // remove commas and convert string to number using the unary plus operator
    return +el.replace(/,/g, "");
  }

  return Number(el).toFixed(3);
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY  -  hh:mm A");
  }
});

Vue.filter("shorten", (value) => {
  return value.slice(0, 15) + "...";
});
