<template>
  <div
    class="home-about-us-holder p-5 reviews-component"
    v-if="homePageData && homePageData.reviews"
  >
    <section>
      <div class="container text-center mb-5 pb-5">
        <h2 class="h1 mb-4 mt-5"><b>Testimonials</b></h2>
        <p class="w-75 m-auto" v-if="homePageData.testimonials_section" v-html="homePageData.testimonials_section"></p>
      </div>
      <div class="slider-holder my-5">
        <VueSlickCarousel
          v-bind="settings"
          v-if="homePageData.reviews && homePageData.reviews.length"
        >
          <div v-for="(review, index) in homePageData.reviews" :key="index">
            <div class="slider-image-holder">
              <div class="floating-top">
                <img
                  src="@/assets/images/home/top.png"
                  alt="top-image"
                  class="img-fluid"
                />
              </div>
              <div class="floating-bottom">
                <img
                  src="@/assets/images/home/bottom.png"
                  alt="bottom-image"
                  class="img-fluid"
                />
              </div>
              <div
                class="img-holder d-flex justify-content-center my-3"
                v-if="review.media_item"
              >
                <img
                  :src="review.media_item"
                  alt="top-image"
                  class="img-fluid"
                />
                <!-- <img
                    src="@/assets/images/home/blog.png"
                    alt="blog-image"
                    class="img-responsive"
                  /> -->
              </div>
              <div class="img-holder d-flex justify-content-center my-3" v-else>
                <img
                  src="https://ata-hr.com/new/public/storage/images/6489812680a1a.jpg"
                  alt="top-image"
                  class="img-fluid"
                />
              </div>
              <div class="rating-holder w-50 m-auto">
                <b-form-rating
                  :value="review.rate"
                  variant="warning"
                  class="mb-2"
                  readonly
                ></b-form-rating>
              </div>
              <h5 v-if="review.name">
                {{ review.name }}
              </h5>
              <div class="content-holder">
                <!-- <p
                  class="text-gray"
                  v-html="review.review.slice(0, 20) + '...'"
                  :id="`tooltip-target-${index}`"
                  v-if="review.review.length > 20"
                ></p> -->
                <!-- <p class="text-gray" v-html="review.review" v-else></p> -->
                <p class="text-gray" v-html="review.review"></p>
                <!-- <b-tooltip :target="`tooltip-target-${index}`" triggers="hover" class="redtexttooltip">
                  <p v-html="review.review"></p>
                </b-tooltip> -->
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <!-- <div class="action-button">
        <button class="btn view-btn">
          <router-link to="/reviews"> View All Reviews </router-link>
        </button>
      </div> -->
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,

        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      reviewValue: null,
    };
  },
  components: {
    VueSlickCarousel,
  },
  props: {
    homePageData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.home-about-us-holder {
  background: #dbf2ff;
  text-align: c;
  .slider-holder {
    margin: auto;
  }
  .slider-image-holder {
    .img-holder {
      img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
      }
    }
  }
  .rating-holder {
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
  .action-button {
    text-align: center;
    .view-btn {
      background: #edf6ff;
      padding: 10px 30px;
      border-radius: 25px;
      margin-top: 5%;
      font-size: 18px;
    }
  }
}
</style>
