<template>
  <div class="courses-holder  home-courses-holder py-5" v-if="homePageData">
    <section class="" v-if="homePageData.courses">
      <div class="container">
        <div class="pt-5 text-center">
          <h1 class="title mb-0">Explore Our Popular Courses</h1>
          <div class="d-flex justify-content-end text-gray">
            <span>
              <router-link :to="{ name: 'courses', query: { id: CoursesId ? CoursesId : 1 } }" class="changeRoute"> View All Courses </router-link>
            </span>
            <!-- <span><font-awesome-icon icon="fa-solid fa-arrow-right" /></span> -->
          </div>
        </div>
      </div>
      <div class="slider-holder">
        <VueSlickCarousel v-bind="settings">
          <div
            v-for="(course, index) in homePageData.courses.filter((item)=> item.media_item)"
            :key="index"
            class="slider-image-holder"
          >
            <div class="slider-card text-center">
              <div
                class="img-holder"
                v-if="course.media_type == 'image'"
              >
                <img
                  :src="course.media_item"
                  alt="slider-image"
                  class="img-fluid w-100"
                />
              </div>
              <div
                class="videoPlayer-holder"
                v-else-if="course.media_type == 'video' && course.link"
              >
                <VideoPlayer :youtubeLink="course.link" />
              </div>
              <div class="content-holder">
                <h2 v-if="course.name">
                  <b>{{ course.name }}</b>
                </h2>
                <h5
                  class="text-gray"><b>{{course.title}}</b></h5>
                <!-- <p
                  class="text-gray"
                  v-if="course.outline"
                  v-html="course.outline"
                ></p> -->
                <hr />
                <div class="action-button">
                  <router-link
                    class="btn btn-block text-center w-100"
                    :to="{ name: 'singleCourse', params: { id: course.id } }"
                  >
                    <span class="learn-btn">Learn More</span>
                    <!-- <span class="ml-5"
                      ><font-awesome-icon icon="fa-solid fa-arrow-right"
                    /></span> -->
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import VideoPlayer from "@/components/pages/Shared/VideoPlayer.vue";
export default {
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        // variableWidth:true,

        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: {
    VueSlickCarousel,
    VideoPlayer,
  },
  props: {
    homePageData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.courses-holder {
  background: #b4d0fe30;
}
.slider-image-holder {
  //padding: 20px;
  border-radius: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 11px 17px #a0aec621;
  margin: 20px;
  width: 95% !important;  
  margin: 20px auto;
  min-width:320px;
  img {
    border-radius: 20px 20px 0 0;
    height: 285px;
  }
  .content-holder {
    padding: 10px 0;
  }
  .action-button {
    button {
      span:last-of-type {
        margin: 0 10px;
      }
    }
  }
}
.slider-image-holder {
  max-width: 330px !important;

  .courses-page {
    .vlt-preview {
      border-radius: 20px 20px 0 0;
      -webkit-border-radius: 20px 20px 0 0;
      -moz-border-radius: 20px 20px 0 0;
      -ms-border-radius: 20px 20px 0 0;
      -o-border-radius: 20px 20px 0 0;
    }
    .vlt-wrapper {
      height: 200px !important;
      border-radius: 25px 25px 0 0 !important;
    }
  }
}



</style>
