<template>
  <div id="app">
    <!-- <LoadingScreen /> -->
    <Navbar />
    <section class="hold-view">
      <router-view />
    </section>
    <Footer />
    <LoadingDataFetch v-if="loadingPageData == true" />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Header/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
// import LoadingScreen from "@/components/layout/LoadingScreen.vue";
import LoadingDataFetch from "@/components/layout/LoadingDataFetch.vue";
export default {
  components: {
    Navbar,
    Footer,
    // LoadingScreen,
    LoadingDataFetch,
  },
  computed: {
    loadingPageData() {
      return this.$store.getters.loadingPageData;
    },
  },
};
</script>
<style lang="scss">
/* width */
::-webkit-scrollbar {
  width: 5px;
}

::-moz-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 10px;
}

::-moz-scrollbar-track {
  box-shadow: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

::-moz-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color;
}

::-moz-scrollbar-thumb:hover {
  background: $main-color;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.top-btn {
  position: fixed;
  right: 20px;
  bottom: 50px;
  padding: 0;
  background: transparent;
  color: #fff;
  border: none;
  margin: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 50%;
  border: 1px solid #d4d5d8;
}
.view {
  min-height: 50vh;
}
.hold-view {
  //margin-top: 138px;
  min-height: 50vh;
 // @media (max-width: 767px) {
   // margin-top: 165px;
 // }
}
.backToTop {
  padding: 15px;
  position: fixed;
  right: 80px;
  bottom: 50px;
  z-index: 99;
}
</style>
