import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import Pages from "@/services/Pages.js";
export default new Vuex.Store({
  state: {
    pageData: null,
    subPageData: null,
    loadingPageData: false,
    pagePagination:null,
    podcastsId: null,
    BlogsId: null,
    AudioId: null,
    MagazineId: null,
    BooksId: null,
    SouvenirsId: null,
    CoursesId: null,
    ContactInfo: null,
    PageImage: null,
    Terms: null,
    Privacy: null,
  },
  getters: {
    PageDate(state) {
      return state.pageData;
    },
    SubPageData(state) {
      return state.subPageData;
    },
    loadingPageData(state) {
      return state.loadingPageData;
    },
    pagePagination(state) {
      return state.pagePagination;
    },
    podcastsId(state) {
      return state.podcastsId;
    },
    BlogsId(state) {
      return state.BlogsId;
    },
    AudioId(state) {
      return state.AudioId;
    },
    MagazineId(state) {
      return state.MagazineId;
    },
    BooksId(state) {
      return state.BooksId;
    },
    SouvenirsId(state) {
      return state.SouvenirsId;
    },
    CoursesId(state) {
      return state.CoursesId;
    },
    ContactInfo(state) {
      return state.ContactInfo;
    },
    PageImage(state) {
      return state.PageImage;
    },
    Terms(state) {
      return state.Terms;
    },
    Privacy(state) {
      return state.Privacy;
    },
  },
  mutations: {
    GET_PAGE_DATA(state, PageContent) {
      state.pageData = PageContent;
    },
    GET_SUB_PAGE_DATA(state, SubPageData) {
      state.subPageData = SubPageData;
    },
    LOADING_DATA(state, loadingData) {
      state.loadingPageData = loadingData;
    },
      PAGE_OAGINATION(state, loadingData) {
      state.pagePagination = loadingData;
    },
    SET_PODCASTS_ID(state, loadingData) {
      state.podcastsId = loadingData;
    },
    SET_BLOGS_ID(state, loadingData) {
      state.BlogsId = loadingData;
    },
    SET_AUDIO_ID(state, loadingData) {
      state.AudioId = loadingData;
    },
    SET_MAGAZINE_ID(state, loadingData) {
      state.MagazineId = loadingData;
    },
    SET_BOOKS_ID(state, loadingData) {
      state.BooksId = loadingData;
    },
    SET_SOUVENIRSId_ID(state, loadingData) {
      state.SouvenirsId = loadingData;
    },
    SET_COURSES_ID(state, loadingData) {
      state.CoursesId = loadingData;
    },
    SET_CONTACT_INFO_DATA(state, loadingData) {
      state.ContactInfo = loadingData;
    },
    SET_PAGE_IMAGE(state, loadingData) {
      state.PageImage = loadingData;
    },
    SET_TERMS(state, loadingData) {
      state.Terms = loadingData;
    },
    SET_PRIVACY(state, loadingData) {
      state.Privacy = loadingData;
    },
  },
  actions: {
    getPageData({ commit }, pageId) {
      commit("LOADING_DATA", true);
      Pages.getPageData(pageId)
        .then((res) => {
          commit("GET_PAGE_DATA", res.data.items.data);
          commit("SET_PAGE_IMAGE", res.data.items);
          commit("PAGE_OAGINATION", res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("LOADING_DATA", false);
        });
    },
    getSubPageData({ commit }, pageId) {
      commit("LOADING_DATA", true);
      Pages.getSubPageData(pageId)
        .then((res) => {
          commit("GET_SUB_PAGE_DATA", res.data.items);
          commit("PAGE_OAGINATION", res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("LOADING_DATA", false);
        });
    },
    getContactInfoData({ commit }) {
      commit("LOADING_DATA", true);
      Pages.getContactInfoData()
        .then((res) => {
          commit("SET_CONTACT_INFO_DATA", res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("LOADING_DATA", false);
        });
    },
    getTerms({ commit }) {
      commit("LOADING_DATA", true);
      Pages.getTerms()
        .then((res) => {
          commit("SET_TERMS", res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("LOADING_DATA", false);
        });
    },
    getPrivacy({ commit }) {
      commit("LOADING_DATA", true);
      Pages.getPolicy()
        .then((res) => {
          commit("SET_PRIVACY", res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("LOADING_DATA", false);
        });
    },
    setBlogsID({ commit }, pageId) {
      commit("SET_BLOGS_ID", pageId);
    },
    setPodcastsID({ commit }, pageId) {
      commit("SET_PODCASTS_ID", pageId);
    },
    setAudioID({ commit }, pageId) {
      commit("SET_AUDIO_ID", pageId);
    },
    setMagazineID({ commit }, pageId) {
      commit("SET_MAGAZINE_ID", pageId);
    },
    setBooksID({ commit }, pageId) {
      commit("SET_BOOKS_ID", pageId);
    },
    setSouvenirsID({ commit }, pageId) {
      commit("SET_SOUVENIRSId_ID", pageId);
    },
    SetCoursesId({ commit }, pageId) {
      commit("SET_COURSES_ID", pageId);
    },
  },
  modules: {},
});
