<template>
  <div class="social">
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.linkedin_link"
    >
      <a class="text-white" :href="ContactUsInfo.linkedin_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/linkedin.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.twitter_link"
    >
      <a class="text-white" :href="ContactUsInfo.twitter_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/twitter.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.facebook_link"
    >
      <a class="text-white" :href="ContactUsInfo.facebook_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/facebook.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.instagram_link"
    >
      <a
        class="text-white"
        :href="ContactUsInfo.instagram_link"
        target="_blank"
      >
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/instagram.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.youtube_link"
    >
      <a class="text-white" :href="ContactUsInfo.youtube_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/youtube.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.whatsapp_number"
    >
      <a
        class="text-white"
        target="_blank"
        :href="`https://api.whatsapp.com/send?phone=${ContactUsInfo.whatsapp_number}&text=Hello`"
      >
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/whatsapp.png"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network threads-image"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.threads_link"
    >
      <a class="text-white" :href="ContactUsInfo.threads_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <img
            src="@/assets/images/social/threads.webp"
            alt="social-network-image"
          />
        </span>
      </a>
    </span>
    <span
      class="social-network"
      data-aos="fade-right"
      data-aos-delay="700"
      v-if="ContactUsInfo.tiktok_link"
    >
      <a class="text-white" :href="ContactUsInfo.tiktok_link" target="_blank">
        <span class="social-link" data-aos="fade-right" data-aos-delay="700">
          <font-awesome-icon icon="fa-brands fa-tiktok" />
        </span>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    ContactUsInfo: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.social-network {
  img {
    width: 35px;
    height: 35px;
    margin: 0 10px;
    border-radius: 5px;
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
}
</style>
