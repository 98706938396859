<template>
  <div class="home">
    <div class="page-wrapper">
      <MainSlider :homePageData="homePageData" />
      <Courses :homePageData="homePageData" />
      <Blogs :homePageData="homePageData" />
      <PodCasts :homePageData="homePageData" />
      <Books :homePageData="homePageData" />
      <Reviews :homePageData="homePageData" />
    </div>
  </div>
</template>

<script>
import MainSlider from "@/components/pages/home/MainSlider";
import Courses from "@/components/pages/home/Courses";
import Blogs from "@/components/pages/home/Blogs";
import PodCasts from "@/components/pages/home/PodCasts";
import Books from "@/components/pages/home/Books";
import Reviews from "@/components/pages/home/Reviews";

// api call
import Pages from "@/services/Pages";
export default {
  name: "HomeView",
  components: {
    MainSlider,
    Courses,
    Blogs,
    PodCasts,
    Books,
    Reviews,
  },
  methods: {
    getHomeData() {
      Pages.getHomeData()
        .then((res) => {
          this.homePageData = res.data.items
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getHomeData();
  },
  data(){
    return {
      homePageData:null
    }
  }
};
</script>
