import globalAxios from "./global-axios";

export default {
  getPageData(payload) {
    return globalAxios.get(`page_items/${payload.id}?page=${payload.page ?payload.page : 1}`);
  },
  getSubPageData(subPageId) {
    return globalAxios.get(`item_details/${subPageId}`);
  },
  getHomeData() {
    return globalAxios.get("homePage");
  },
  getContactInfoData() {
    return globalAxios.get("contact_info");
  },
  getTerms() {
    return globalAxios.get("terms");
  },
  getPolicy() {
    return globalAxios.get("policy");
  },
  getReviewsPageData() {
    return globalAxios.get("reviews");
  },
  SearchResults(searchText) {
    return globalAxios.get("search", {
      params: {
        search_text: searchText,
      },
    });
  },
};
