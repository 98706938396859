<template>
  <div class="top-header" v-if="ContactUsInfo">
    <div class="topNavHolder">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-3 col-md-0 col-sm-4 margin-small d-none d-lg-block"
          >
            <router-link to="/">
              <img
                src="@/assets/images/logo.png"
                height="160"
                alt="logo"
                class="logo-image"
              />
            </router-link>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 contact-dial">
            <div class="row">
              <div class="col-6">
                <div class="usa">
                  <p class="contact-info">
                    <span class="icon-image">
                      <img
                        src="@/assets/images/contactUs/usa.png"
                        alt="usa-image"
                      />
                    </span>
                    <span class="main-color h6"><b>USA Office</b></span>
                  </p>
                  <p>
                    <span class="phone-call" v-if="ContactUsInfo.hotline_usa" v-html="ContactUsInfo.hotline_usa"
                      ></span
                    >

                  </p>
                  <p v-if="ContactUsInfo.location_usa" v-html="ContactUsInfo.location_usa"></p>
                </div>
              </div>
              <div class="col-6">
                <div class="eg">
                  <p class="contact-info">
                    <span class="icon-image">
                      <img
                        src="@/assets/images/contactUs/eg.png"
                        alt="eg-image"
                      />
                    </span>
                    <span class="main-color h6"><b>EGYPT Office</b></span>
                  </p>
                  <p>
                    <span class="phone-call" v-if="ContactUsInfo.hotline_eg" v-html="ContactUsInfo.hotline_eg"
                      ></span
                    >
                  </p>
                  <p v-if="ContactUsInfo.location_eg" v-html="ContactUsInfo.location_eg"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12 social-search">
            <Social :ContactUsInfo="ContactUsInfo" />
            <div class="contact-info">
              <form class="search-container" @submit.prevent="search">
                <input
                  type="text"
                  id="search-bar"
                  placeholder="Search"
                  v-model="searchWord"
                />
                <a class="icon-holder" @click.prevent="search"
                  ><font-awesome-icon
                    icon="fa-solid fa-magnifying-glass text-white"
                /></a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Social from "./Social.vue";
export default {
  components: {
    Social,
  },
  computed: {
    ContactUsInfo() {
      return this.$store.getters.ContactInfo;
    },
  },
  data() {
    return {
      searchWord: null,
    };
  },
  methods: {
    search() {
      this.$router.push(
        {
          name: "searchResults",
          query: {
            searchWord: this.searchWord,
          },
        },
        () => {
          this.$router.go(0);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.topNavHolder {
  background: $header-footer-color;
  color: #000;
  width: 100%;
  padding: 20px 5px;

  // top nav style

  .contact-info {
    p {
      font-size: 17px;
      font-weight: bold;
    }
    img {
      width: 30px;
      height: 20px;
      border-radius: 0px;
      object-fit: cover;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
    .margin-small {
      margin-bottom: 10px;
    }
    .contact-dial {
      justify-content: center;
      align-items: center;
    }
    .social-search {
      margin: 20px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .search-container {
    position: relative;
    margin-top: 20px;
    input#search-bar {
      margin: 0 auto;
      width: 100%;
      height: 45px;
      padding: 0 20px;
      font-size: 1rem;
      border: 2px solid $gray;
      outline: none;
      min-width: 300px !important;
      border-radius: 25px;
      &:focus {
        border: 2px solid $main-color;
        transition: 0.35s ease;
        color: #008abf;
        &::-webkit-input-placeholder {
          transition: opacity 0.45s ease;
          opacity: 0;
        }
        &::-moz-placeholder {
          transition: opacity 0.45s ease;
          opacity: 0;
        }
        &:-ms-placeholder {
          transition: opacity 0.45s ease;
          opacity: 0;
        }
      }
    }

    .icon-holder {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      background: $main-color;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff !important;
    }
  }
  .social-search {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    .social-search {
      margin: 20px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
