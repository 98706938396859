<template>
  <div class="Books-holder py-5" v-if="homePageData">
    <section v-if="homePageData.books">
      <div class="container">
        <div class="home-title text-center mb-5">
          <h1 class="title mb-0">Books</h1>
          <div class="d-flex justify-content-end text-gray">
            <router-link :to="{ name: 'books', query: { id: BooksId } }" class="changeRoute">
              <span>All Books</span>
              <!-- <span><font-awesome-icon icon="fa-solid fa-arrow-right" /></span> -->
            </router-link>
          </div>
        </div>
        <div class="row align-items-center">
          <div
            class="col-md-4 col-sm-12 mb-3"
            v-for="(book, index) in homePageData.books.slice(0,6)"
            :key="index"
          >
            <div class="books-holder">
              <div
                class="img-holder text-center"
                v-if="book.media_type == 'image'"
              >
                <img
                  :src="book.media_item"
                  alt="img"
                  class="img-responsive"
                  v-if="book.media_item"
                />
              </div>
              <div
                class="videoPlayer-holder"
                v-else-if="book.media_type == 'video' && book.link"
              >
                <VideoPlayer :youtubeLink="book.link" />
              </div>
              <div
                class="content-holder my-4 px-4"
                v-if="book.name || book.author"
              >
                <span class="main-color"
                  ><font-awesome-icon icon="fa-regular fa-user"
                /></span>
                <span class="text-gray" v-if="book.author">{{
                  book.author
                }}</span>
                <p v-if="book.name">{{ book.name }}</p>
              </div>
              <div class="price" v-if="book.price">
                <span
                  ><b>{{ book.price }}</b></span
                >
                
              </div>
              <router-link class="d-flex justify-content-center align-items-center" :to="{ name: 'singleBook', params: { id: book.id } }">
                <button class="btn btn-block mb-2 main-color border-main">
                  More Details
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VideoPlayer from "@/components/pages/Shared/VideoPlayer.vue";
export default {
  props: {
    homePageData: {
      type: Object,
    },
  },
  components: {
    VideoPlayer,
  },
};
</script>

<style lang="scss" scoped>
.Books-holder {
  background: #d8e4f763;

  .books-holder {
    background: #fff;
    padding: 70px 0 10px 0;
    border-radius: 10px;
    position: relative;
    min-height: 31rem;
    img {
      margin: auto;
      width: 95%;
      height: 300px;
      object-fit: cover;
    }
    .price {
      position: absolute;
      top: 15px;
      right: 0;
      border-radius: 50px 0 0 50px;
      background: $main-color;
      color: #fff;
      padding: 5px 15px;
    }
  }
}
</style>
