<template>
  <div class="homeMainSlider" v-if="homePageData && homePageData.slider">
    <VueSlickCarousel v-bind="settings">
      <div
        v-for="(slider, index) in homePageData.slider"
        :key="index"
        class="slider-image-data"
        :style="{ 'background-image': 'url(' + slider.media_item + ')' }"
      >
        <div class="" v-if="slider.media_type == 'image'">
          <!-- <div class="image-holder" v-if="slider.media_type == 'image'">
          <img
            :src="slider.media_item"
            draggable="false"
            alt=""
            class="slider-image"
          />
        </div> -->
          <div class="slider-data">
            <h2 class="h1 w-75">
              <span v-if="slider.title" v-html="slider.title"></span> <br />
              <span v-if="slider.pref" v-html="slider.pref"></span>
            </h2>
            <!-- <p class="w-75">
            Meet University,And Cultural Institutions, Who'll Share Their
            Experience.
          </p> -->
            <div class="my-4" v-if="slider.link && slider.btn_text">
              <a :href="slider.link" target="_blank">
                <button class="bg-main text-white">
                  {{ slider.btn_text }}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="" v-if="slider.media_type == 'video'">
          <div class="slider-data">
            <div class="videoPlayer-holder">
              <VideoPlayer :youtubeLink="slider.link" />
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
// @ is an alias to /src
import VueSlickCarousel from "vue-slick-carousel";
import VideoPlayer from "@/components/pages/Shared/VideoPlayer.vue"
export default {
  components: {
    VueSlickCarousel,
    VideoPlayer
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,

        responsive: [
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  props: {
    homePageData: {
      type: Object,
    },
  },
};
</script>
