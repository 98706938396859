<template>
  <div class="nav-wrapper">
    <div class="hold-all">
      <TopNav />

      <div class="hold-all-data-with-container">
        <div class="container-fluid">
          <div class="nav-menu">
            <div class="row justify-content-between align-items-center">
              <div class="col-6">
                <div class="logo" v-if="showMobileMenu">
                  <router-link to="/">
                    <img
                      src="@/assets/images/logo.png"
                      height="65"
                      alt="logo"
                      class="logo-image"
                    />
                  </router-link>
                </div>
              </div>
              <div class="col-6" v-if="showMobileMenu">
                <!-- <span :class="{ 'float-right': $i18n.locale == 'en', 'float-left': $i18n.locale == 'ar' }"> -->
                <span class="float-right burger-icon">
                  <font-awesome-icon
                    icon="fa-solid fa-bars"
                    size="2x"
                    v-b-toggle.sidebar-backdrop
                  />
                </span>
              </div>
            </div>
            <!-- <div class="nav-content" :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"> -->
            <div class="nav-content" v-if="!showMobileMenu">
              <div
                class="row justify-content-center align-items-center text-center"
              >
                <div class="col-12">
                  <NavLinks :navLinks="navLinks" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side-menu" v-if="showMobileMenu">
          <b-sidebar
            id="sidebar-backdrop"
            :backdrop-variant="variant"
            backdrop
            shadow
            ref="sideMenu"
          >
            <div class="px-3 py-2 hold-logo-links">
              <div class="logo">
                <router-link to="/">
                  <img
                    src="@/assets/images/logo.png"
                    height="65"
                    alt="logo"
                    class="logo-image"
                  />
                </router-link>
              </div>
              <NavLinks :navLinks="navLinks" />
            </div>
            <div>
              <!-- <Search /> -->

              <!-- <SearchModal /> -->
            </div>
          </b-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Search from "./Search.vue";
import NavLinks from "./Links.vue";
import TopNav from "./TopNav.vue";
import SearchModal from "./SearchModal";
import Header from "@/services/Header";
export default {
  data() {
    return {
      showMobileMenu: true,
      variant: "dark",
      navLinks: null,
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    resizeScreen() {
      if (window.innerWidth <= 992) {
        this.showMobileMenu = true;
        // this.$cookiz.set('mobileView', 'mobile')
      } else {
        this.showMobileMenu = false;
        // this.$cookiz.set('mobileView', 'deskTop')
      }
    },
    async logout() {
      await this.$store.dispatch("LogOut");
      localStorage.removeItem("vuex");
      this.$router.push("/login");
    },
    switchLang() {
      if (this.lang === "en") {
        this.lang = "ar";
      } else {
        this.lang = "en";
      }
      localStorage.setItem("lang", this.lang);
      window.location.reload();
    },
    getHeaderLinks() {
      Header.getHeaderLinks()
        .then((res) => {
          this.navLinks = res.data.items;
          if (res.data.items.Resources) {
            res.data.items.Resources.forEach((element) => {
              if (element.name == "Podcast") {
                this.$store.dispatch("setPodcastsID", element.id);
              }
              if (element.name == "Blog") {
                this.$store.dispatch("setBlogsID", element.id);
              }
            });
          }
          if (res.data.items.Store) {
            res.data.items.Store.forEach((element) => {
              if (element.name == "Audio") {
                this.$store.dispatch("setAudioID", element.id);
              }
              if (element.name == "Magazine") {
                this.$store.dispatch("setMagazineID", element.id);
              }
              if (element.name == "Books") {
                this.$store.dispatch("setBooksID", element.id);
              }
              if (element.name == "Souvenirs") {
                this.$store.dispatch("setSouvenirsID", element.id);
              }
            });
          }
          if (res.data.items.Service) {
            res.data.items.Service.forEach((element) => {
              if (element.name == "Learning and Development courses") {
                this.$store.dispatch("SetCoursesId", element.id);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    makeHeaderSticky() {
      const header = document.querySelector(".hold-all-data-with-container");
      const toggleClass = "is-sticky";

      window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll > 150) {
          header.classList.add(toggleClass);
        } else {
          header.classList.remove(toggleClass);
        }
      });
    },
  },
  mounted() {
    this.getHeaderLinks();
    window.addEventListener("resize", () => {
      this.resizeScreen();
    });
    window.addEventListener("load", () => {
      this.resizeScreen();
    });
    this.resizeScreen();
    window.addEventListener("scroll", this.makeHeaderSticky);
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
  },
  components: {
    // Search,
    NavLinks,
    TopNav,
    SearchModal,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/layout/Navbar.scss";
.logo-image {
  object-fit: fill;
}
.btn-search {
  padding: 8px 12px;
  border-radius: 5px;
  text-align: center;
}
.hold-all {
  background: $header-footer-color !important;
}

.hold-all-data-with-container{
  transition: all .3 ease-in-out;
  z-index: 9999999;
  width: 100%;
}
.hold-all-data-with-container.is-sticky {
  position: fixed;
  animation: slideDown 0.35s ease-out;
 
  top: 0;
  left: 0;
  right: 0;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
