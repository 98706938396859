import axios from "axios";
import router from "../router/index";

const globalAxios = axios.create({
  baseURL: process.env.VUE_APP_AXSIOS_LINK,
  headers: {
    "Accept-Language": "en",
  },
});

globalAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response.status == 403 && userExist) ||
      (error.response.status == 401 && userExist)
    ) {
      router.push(`/`);
    }

    return Promise.reject(error);
  }
);

export default globalAxios;
