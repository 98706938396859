import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/studyAbroad",
    name: "studyAbroad",
    component: () => import("../views/StudyAbroad.vue"),
  },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () => import("../views/Recruitment.vue"),
  },
  {
    path: "/mediaCenter",
    name: "mediaCenter",
    component: () => import("../views/MediaCenter.vue"),
    props: true
  },
  {
    path: "/designCourse",
    name: "designCourse",
    component: () => import("../views/DesignCourse.vue"),
  },
  {
    path: "/formCompleted",
    name: "formCompleted",
    component: () => import("../views/FormCompleted.vue"),
  },
  {
    path: "/conference",
    name: "conference",
    component: () => import("../views/Events/Conference.vue"),
  },
  {
    path: "/summerProgram",
    name: "summerProgram",
    component: () => import("../views/Events/SummerProgram.vue"),
  },
  {
    path: "/searchResults",
    name: "searchResults",
    component: () => import("../views/SearchResults.vue"),
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../views/Reviews/Reviews.vue"),
  },
  {
    path: "/courses",
    name: "courses",
    component: () => import("../views/Courses/Courses.vue"),
  },
  {
    path: "/courses/:id",
    name: "singleCourse",
    component: () => import("../views/Courses/SingleCourse.vue"),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("../views/Services/Business.vue"),
  },

  {
    path: "/connectWithCoach",
    name: "connectWithCoach",
    component: () => import("../views/Services/ConnectWithCoach.vue"),
  },
  {
    path: "/connectWithMentor",
    name: "connectWithMentor",
    component: () => import("../views/Services/ConnectWithMentor.vue"),
  },
  {
    path: "/magazine",
    name: "magazine",
    component: () => import("../views/Magazines/Magazine.vue"),
  },
  {
    path: "/magazine/:id",
    name: "singleMagazine",
    component: () => import("../views/Magazines/SingleMagazine.vue"),
  },
  {
    path: "/podcasts",
    name: "podcasts",
    component: () => import("../views/Podcasts/Podcasts.vue"),
  },
  {
    path: "/podcasts/:id",
    name: "singlePodcast",
    component: () => import("../views/Podcasts/SinglePodcast.vue"),
  },
  {
    path: "/books",
    name: "books",
    component: () => import("../views/Books/Books.vue"),
  },
  {
    path: "/books/:id",
    name: "singleBook",
    component: () => import("../views/Books/SingleBook.vue"),
  },
  {
    path: "/Souvenirs",
    name: "souvenirs",
    component: () => import("../views/Souvenirs/Souvenirs.vue"),
  },
  {
    path: "/Souvenirs/:id",
    name: "singleSouvenir",
    component: () => import("../views/Souvenirs/SingleSouvenir.vue"),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import("../views/Blogs/Blogs.vue"),
  },
  {
    path: "/blogs/:id",
    name: "singleBlog",
    component: () => import("../views/Blogs/SingleBlog.vue"),
  },
  {
    path: "/audios",
    name: "audios",
    component: () => import("../views/Audios/Audios.vue"),
  },
  {
    path: "/audios/:id",
    name: "singleAudio",
    component: () => import("../views/Audios/SingleAudio.vue"),
  },
  {
    path:'/enroll/:id',
    name:'enroll',
    component: ()=> import ('../views/Enroll.vue'),
    props:true,
  },
  {
    path:'/termsConditions',
    name:'termsConditions',
    component: ()=> import ('../views/TermsConditions.vue'),
  },
  {
    path:'/privacyPolicy',
    name:'privacyPolicy',
    component: ()=> import ('../views/PrivacyPolicy.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
