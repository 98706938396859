<template>
  <div class="Podcasts-holder py-5" v-if="homePageData">
    <section v-if="homePageData.podcasts">
      <div class="container">
        <div class="home-title text-center mb-5">
          <h1 class="title mb-0">Podcasts</h1>
          <div class="d-flex justify-content-end text-gray">
            <span>
              <router-link :to="{ name: 'podcasts', query: { id: pageId } }" class="changeRoute">All Podcasts</router-link>
            </span>
            <!-- <span><font-awesome-icon icon="fa-solid fa-arrow-right" /></span> -->
          </div>
        </div>
        <div class="row align-items-center">
          <div
            class="col-xl-4 col-md-6 col-sm-12 mb-3"
            v-for="(podcast, index) in homePageData.podcasts.slice(0,6)"
            :key="index"
          >
            <div class="">
              <PodcastCard :podcast="podcast" />
            </div>
          </div>
          <!-- <div class="">
            <ServicesCard :courses="homePageData.podcasts" :buttonTitle="'Listen Now'" />
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PodcastCard from "@/components/pages/Podcasts/PodcastCard.vue";
// import ServicesCard from "@/components/pages/Services/PodcastsServicesCard.vue";
export default {
  computed: {
    pageId() {
      return this.$store.getters.podcastsId;
    },
  },
  props: {
    homePageData: {
      type: Object,
    },
  },
  components: {
    PodcastCard,
    // ServicesCard
  }
};
</script>

<style lang="scss" scoped>
.Podcasts-holder {
  background: #d8e4f763;

  .podcast-holder {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    min-height: 185px;

    img {
      border-radius: 10px;
      width: 115px;
      height: 140px;
      object-fit: cover;
    }

    .media-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
