<template>
  <div class="blogs-holder" v-if="homePageData">
    <section v-if="homePageData.blogs">
      <div class="container">
        <div class="home-title text-center mb-5">
          <h1 class="title mb-0">Our Latest Blogs</h1>
          <div class="d-flex justify-content-end text-gray">
            <router-link :to="{ name: 'blogs', query: { id: BlogsId } }" class="changeRoute">
              <span>Read All Blogs</span>
              <!-- <span><font-awesome-icon icon="fa-solid fa-arrow-right" /></span> -->
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12 mb-3" v-for="(blog, index) in homePageData.blogs.slice(0,6)" :key="index">
            <div class="blog-card">
              <div class="img-holder" v-if="blog?.media_type == 'image' && blog.media_item">
                <img :src="blog.media_item" alt="blog-image" class="img-responsive w-100" v-if="blog.media_item" />
                <b-img v-else v-bind="mainProps" rounded="0" alt="Not rounded image"></b-img>
              </div>

              <div class="videoPlayer-holder" v-else-if="blog.media_type == 'video' && blog.link">
                <VideoPlayer :youtubeLink="blog.link" />
              </div>

              <div class="content-holder p-4">
                <p class="my-3" v-if="blog.name">
                  {{ blog.name }}
                </p>
                <div class="row justify-content-between align-items-center" v-if="blog.author || blog.date">
                  <div class="col-xl-12 col-sm-12" v-if="blog.author">
                    <span class="main-color"><font-awesome-icon icon="fa-regular fa-user" /></span>
                    <span class="text-gray">{{ blog.author }}</span>
                  </div>
                  <div class="col-xl-12 col-sm-12" v-if="blog.date">
                    <span class="main-color"><font-awesome-icon icon="fa-solid fa-calendar-days" /></span>
                    <span class="text-gray">{{ blog.date | formatDate }}</span>
                  </div>
                </div>
                <div class="card-content">
                  <div class="action-button my-2">
                    <router-link class="btn btn-block m-0 p-0" :to="{ name: 'singleBlog', params: { id: blog.id } }">
                      <span class="bg-main-light">Read More</span>
                      <!-- <span class=""
                        ><font-awesome-icon icon="fa-solid fa-arrow-right"
                      /></span> -->
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VideoPlayer from "@/components/pages/Shared/VideoPlayer.vue";
export default {
  props: {
    homePageData: {
      type: Object,
    },
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
    };
  },
  components:{
    VideoPlayer
  }
};
</script>

<style lang="scss" scoped>
section {
  padding: 5% 0;

  .blog-card {
    border: 1px solid $gray;
    border-radius: 30px;
    width: 97%;
    margin: auto;

    img {
      border-radius: 30px 30px 0 0;
      height: 300px;
      object-fit: cover;
    }
  }
}
</style>
