<template>
  <div class="podcast-card" v-if="podcast">
    <div class="">
      <div class="">
        <div class="podcast-holder">
          <router-link
            :to="{ name: 'singlePodcast', params: { id: podcast.id } }"
          >
            <b-media class="d-flex">
              <template #aside v-if="podcast.audio_image">
                <!-- <b-img
                        blank
                        blank-color="#ccc"
                        width="64"
                        alt="placeholder"
                      ></b-img> -->
                <img :src="podcast.audio_image" alt="img" />
              </template>
              <h5>
                <b>{{ podcast.title }}</b>
              </h5>
              <h5 class="mt-0 h6">
                <span class="main-color"
                  ><font-awesome-icon icon="fa-regular fa-user" size="xs"
                /></span>
                <span class="text-gray">{{ podcast.author }}</span>
              </h5>
             
              <p class="text-gray" v-if="podcast.episode">
                {{ podcast.episode }} Episodes
              </p>
              <div class="">
                <span class="main-color"
                  ><font-awesome-icon
                    icon="fa-solid fa-calendar-days"
                    size="xs"
                /></span>
                <span class="text-gray">{{ podcast.date | formatDate }}</span>
              </div>
              <router-link :to="{ name: 'singlePodcast', params: { id: podcast.id } }" class="main-color">
                <span class="learn-btn2 text-capitalize">listen now</span>
                <!-- <span><font-awesome-icon icon="fa-solid fa-arrow-right" /></span> -->
              </router-link>
            </b-media>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    podcast: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.Podcasts-holder {
  background: #d8e4f763;
  .podcast-holder {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    min-height: 185px;
    img {
      border-radius: 10px;
      width: 115px;
      height: 140px;
      object-fit: cover;
    }
    .media-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.image-holder {
  img {
    width: 30rem;
    height: 30rem;
    border-radius: 25px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
.slide-recommend-holder {
  .podcast-holder {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    img {
      border-radius: 10px;
      width: 115px;
      height: 140px;
      object-fit: cover;
    }
    .media-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
